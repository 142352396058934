<template>
  <ApiLoadingController :params="params" :fetch="fetch">
    <template #default="{ data }">
      <GridMatchButtons v-bind="$attrs" :item="{ ...item, ...data }" :scrims="scrims" />
    </template>
    <template #loading>
      <GridMatchButtons v-bind="$attrs" :item="item" loading :scrims="scrims" />
    </template>
  </ApiLoadingController>
</template>

<script>
import { mapGetters } from 'vuex'

import { getEmeaBroadcastMatch, getMatchFiles, hasGridRiotLiveEventsTimeSeries } from '@/api/grid'
import ApiLoadingController from '@/components/controllers/ApiLoadingController.vue'
import GridMatchButtons from '@/components/grid/cells/GridMatchButtons.vue'

export default {
  name: 'GridMatchButtonsController',
  components: { ApiLoadingController, GridMatchButtons },
  props: {
    apiKey: String,
    item: Object,
    scrims: Boolean,
  },
  computed: {
    ...mapGetters({
      can_access_grid_emea_matches: 'auth/can_access_grid_emea_matches',
      mapsByAnything: 'static/mapsByAnything',
    }),
    params() {
      return {
        apiKey: this.apiKey,
        mapId: this.item.map,
        scrims: this.scrims,
        seriesId: this.item.seriesId,
        seriesState: this.item.seriesState,
        source: this.item.source,
        type: this.item.type,
      }
    },
  },
  methods: {
    async fetch({ apiKey, mapId, scrims, seriesId, seriesState, source, type }, config) {
      if (type !== 'game') return
      if (scrims) {
        return {
          ready: scrims,
        }
      }
      const [{ riotEventsAvailable = false } = {}, emeaSeries] = await Promise.all([
        (async () => {
          try {
            const matchFiles = await getMatchFiles({ seriesId }, { ...config, apiKey })

            const riotEventsAvailable = matchFiles.files.some(
              file => file.id === 'events-riot-compressed' && file.status === 'ready'
            )
            const allGridFilesReady = riotEventsAvailable && matchFiles.files.every(file => file.status === 'ready')

            return { riotEventsAvailable, allGridFilesReady, matchFiles }
          } catch (e) {
            console.warn('cannot load series files', e)
          }
        })(),
        (async () => {
          if (!this.scrims && this.can_access_grid_emea_matches) {
            try {
              return await getEmeaBroadcastMatch({ id: seriesId }, { ...config, apiKey })
            } catch (e) {
              console.warn('cannot load emea series', e)
            }
          }
        })(),
      ])

      const emeaMatch = emeaSeries?.find(g => this.mapsByAnything[g?.map]?.id === mapId)

      const ready =
        riotEventsAvailable &&
        source === 'grid' &&
        // last updated 45 min ago or we check that riot events are available
        (Date.now() - Date.parse(seriesState?.updatedAt) > 45 * 60 * 1000 ||
          (await hasGridRiotLiveEventsTimeSeries({ seriesId, mapName: this.mapsByAnything[mapId].name }, config)))
      const live = emeaMatch?.rounds?.some(r => r?.inferences)

      return {
        live,
        ready,
      }
    },
  },
}
</script>

<style scoped></style>
