<template>
  <div class="home matches-page">
    <PageTitle v-if="feed === 'SCRIM'">Grid Scrims</PageTitle>
    <PageTitle v-else>Grid Matches</PageTitle>

    <AppTabs lazy>
      <AppTab title="Search">
        <SearchStateController ref="searchCtrl" :default="defaultFilters" :s="s" @change="onSearchChange">
          <template #default="{ state }">
            <GridSearchFilters
              :api-key="apiKey"
              :disabled="!!loading"
              :period-filter="past"
              :scrims="feed === 'SCRIM'"
              :value="state"
              @update="onSearch"
            />

            <hr />

            <div class="alert alert-info d-flex justify-content-between" v-if="exporting">
              <div class="d-flex align-items-center">
                <b-spinner small type="grow" variant="primary" />
                <span class="ml-2">Exporting...</span>
              </div>
              <button class="btn btn-outline-danger btn-sm" @click="cancelExport">Cancel</button>
            </div>

            <div class="alert alert-danger d-flex justify-content-between" v-if="error">
              <div class="d-flex align-items-center">
                <b-icon-exclamation-triangle />
                <span class="ml-2" style="white-space: pre-wrap">{{ error }}</span>
              </div>
              <button class="btn btn-outline-danger btn-sm" @click="error = null">Dismiss</button>
            </div>

            <AppTabs>
              <AppTab title="Found matches">
                <GridMatchSearchController
                  :filter="state"
                  :per-page="perPage"
                  @update:per-page="updateItemsPerPage"
                  :selected.sync="selectedMatches"
                  :tracking-context="{ page: 'grid-matches', section: 'results' }"
                  :api-key="apiKey"
                  :feed="feed"
                  :past="past"
                  :current="current"
                  @export="exportMatch"
                  :future="future"
                />
              </AppTab>

              <AppTab :title="`Selected matches (${selectedMatchesCount})`">
                <GridMatchTable
                  :items="selectedMatches"
                  :sort-by="'created'"
                  :selected.sync="selectedMatches"
                  :sort-desc="true"
                  :api-key="apiKey"
                  v-bind="$attrs"
                  v-on="$listeners"
                  :bookmarkable="false"
                  :scrims="feed === 'SCRIMS'"
                />
              </AppTab>

              <MapTab
                :active.sync="mapTool.active"
                :matches="selectedMatches"
                :map.sync="mapTool.map"
                :grid="true"
                :team.sync="mapTool.team"
                :tracking-context="{ page: 'matches', section: 'map' }"
                :api-key="apiKey"
              />

              <!-- <AppTab lazy :disabled="!selectedMatches.length" title="NEW Map">
                <MapTool v-bind="mapTool" :matches="selectedMatches" />
              </AppTab> -->
            </AppTabs>
          </template>
        </SearchStateController>
      </AppTab>

      <AppTab title="Bookmarked" v-if="false">
        <BookmarkedMatchesController
          :per-page="perPage"
          @update:per-page="updateItemsPerPage"
          :tracking-context="{ page: 'matches', section: 'bookmarked' }"
        />
      </AppTab>
    </AppTabs>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue'
import { BIconExclamationTriangle } from 'bootstrap-vue'
import px from 'vue-types'
import { mapGetters, mapMutations } from 'vuex'

import BookmarkedMatchesController from '@/components/controllers/BookmarkedMatches.vue'
import SearchStateController from '@/components/controllers/SearchStateController.vue'
import PageTitle from '@/components/generic/PageTitle.vue'
import AppTab from '@/components/generic/Tab.vue'
import AppTabs from '@/components/generic/Tabs.vue'
import GridMatchSearchController from '@/components/grid/GridMatchController.vue'
import GridMatchTable from '@/components/grid/GridMatchTable.vue'
import GridSearchFilters from '@/components/grid/SearchFilters.vue'
import { pxNullable } from '@/components/Map/types.js'
import MapTab from '@/components/tabs/MapTab.vue'
import mixpanel from '@/mixpanel.js'
import { DEFAULT_ITEMS_PER_PAGE } from '@/store/modules/search'
import downloadGridMatchesExport from '@/utils/downloadGridMatchesExport'
import { serializeQuery } from '@/utils/searchQuery.js'

export default {
  name: 'GridMatches',
  components: {
    BIconExclamationTriangle,
    SearchStateController,
    GridMatchSearchController,
    BookmarkedMatchesController,
    MapTab,
    AppTab,
    AppTabs,
    GridSearchFilters,
    PageTitle,
    GridMatchTable,
  },
  props: {
    s: pxNullable(px.string).def(null),
    manualKey: px.string,
    feed: String,
    past: Boolean,
    current: Boolean,
    future: Boolean,
  },
  data() {
    return {
      exporting: false,
      error: null,
      loading: 0,
      perPage: DEFAULT_ITEMS_PER_PAGE,
      mapTool: {
        active: false,
        map: null,
        team: null,
      },
      selectedMatches: [],
    }
  },
  computed: {
    ...mapGetters({
      defaultFilters: 'searchGrid/matchSearchFilters',
      defaultItemsPerPage: 'searchGrid/itemsPerPage',
      gridKey: 'auth/grid_key',
      mapsById: 'static/mapsById',
    }),
    selectedMatchesCount() {
      return this.selectedMatches?.length
    },
    apiKey() {
      return this.manualKey || this.gridKey
    },
  },
  mounted() {
    this.perPage = this.defaultItemsPerPage
  },
  methods: {
    ...mapMutations({
      resetFilters: 'searchGrid/RESET_FILTERS',
      saveFilters: 'searchGrid/SET_FILTERS',
      setItemsPerPage: 'searchGrid/SET_ITEMS_PER_PAGE',
    }),

    cancelExport() {
      if (this.exporting) {
        this.exporting.abort()
        this.exporting = false
      }
    },

    async doExport(match, source) {
      this.error = null
      this.exporting = new AbortController()
      try {
        await downloadGridMatchesExport(
          {
            seriesId: match.seriesId,
            map: this.mapsById[match.map]?.name,
            redTeam: match.teams.find(team => team.team_side === 'Red')?.name,
            blueTeam: match.teams.find(team => team.team_side === 'Blue')?.name,
            ...(this.apiKey && { apiKey: this.apiKey }),
          },
          {
            trackOpts: { source },
            signal: this.exporting.signal,
          }
        )
      } catch (e) {
        if (e.name !== 'AbortError') {
          this.error = e
          Sentry.captureException(e)
        }
      } finally {
        this.exporting = false
      }
    },

    exportMatch(match) {
      this.doExport(match, 'grid matches list')
    },

    exportSelectedMatches() {
      this.doExport(
        this.selectedMatches.map(({ id }) => id),
        'selected matches'
      )
    },

    async onSearch(query) {
      this.loading++
      try {
        this.selectedMatches = []
        const serializedQuery = await serializeQuery(query)
        if (query == null) {
          this.resetFilters()
        } else {
          this.saveFilters(query)
        }
        if (this.s !== serializedQuery) {
          await this.$router.push({
            query: { s: serializedQuery != null ? serializedQuery : undefined, key: this.apiKey || undefined },
          })
        } else {
          await this.$refs.searchCtrl.retry()
        }
      } finally {
        this.loading--
      }

      mixpanel.track_search(query, query ? 'Search' : 'Reset')
    },

    onSearchChange() {
      this.selectedMatches = []
    },

    updateItemsPerPage(count) {
      this.perPage = count
      this.setItemsPerPage(count)
    },
  },
}
</script>

<style lang="scss" scoped>
.list-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
}
</style>
