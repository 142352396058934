<template>
  <ApiLoadingController :fetch="fetch" :params="params">
    <template #default="{ data }">
      <VodStatusIndicator :status="data.status" :match_id="matchId" />
    </template>
    <template #loading>
      <Loading label="" />
    </template>
  </ApiLoadingController>
</template>

<script>
import { mapGetters } from 'vuex'

import { getEmeaBroadcastMatch } from '@/api/grid'
import ApiLoadingController from '@/components/controllers/ApiLoadingController.vue'
import Loading from '@/components/generic/Loading.vue'
import VodStatusIndicator from '@/components/UI/VodStatusIndicator.vue'
import findEsportMatchesFromGridSeries from '@/utils/findEsportMatchesFromGridSeries'

export default {
  name: 'GridVodStatusIndicator',
  components: { VodStatusIndicator, ApiLoadingController, Loading },
  props: {
    emeaMatchVodStatus: String,
    seriesState: Object,
    scrim: Boolean,
    mapId: String,
    matchId: String,
    apiKey: String,
  },
  computed: {
    ...mapGetters({
      can_access_public_matches: 'auth/can_access_public_matches',
      mapsByAnything: 'static/mapsByAnything',
      can_access_grid_emea_matches: 'auth/can_access_grid_emea_matches',
    }),
    params() {
      return {
        emeaMatchVodStatus: this.emeaMatchVodStatus,
        seriesState: this.seriesState,
        scrim: this.scrim,
        mapId: this.mapId,
        apiKey: this.apiKey,
      }
    },
  },
  methods: {
    async fetch({ seriesState, scrim, mapId, apiKey }, config) {
      let esportMatches = null
      if (this.can_access_public_matches && !scrim) {
        try {
          esportMatches = await findEsportMatchesFromGridSeries(seriesState, config)
        } catch (e) {
          console.warn('cannot find matching esport matches', e)
        }
      }
      const esportMatch = esportMatches?.find(match => match?.map === mapId)

      let emeaMatchVodStatus = null
      if (!scrim && this.can_access_grid_emea_matches) {
        try {
          if (seriesState?.id) {
            const emeaSeries = await getEmeaBroadcastMatch({ id: seriesState.id }, { ...config, apiKey })
            const emeaMatch = emeaSeries?.find(g => this.mapsByAnything[g?.map]?.id === mapId)
            emeaMatchVodStatus = emeaMatch?.rounds?.reduce(
              ({ rounds, clips }, r) => {
                rounds++
                if (r?.clips?.full) {
                  clips++
                }
                return { rounds, clips, status: rounds > clips ? 'partial' : 'succeeded' }
              },
              { rounds: 0, clips: 0 }
            )?.status
          }
        } catch (e) {
          console.warn('cannot load emea series', e)
        }
      }

      return {
        status:
          esportMatch && esportMatch.vod_status === 'succeeded'
            ? esportMatch.vod_status
            : emeaMatchVodStatus
            ? emeaMatchVodStatus
            : esportMatch && esportMatch.vod_status,
      }
    },
  },
}
</script>

<style scoped></style>
