var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppTable',_vm._g(_vm._b({ref:"table",staticClass:"tabe",attrs:{"fields":_vm.fields,"items":_vm.computedItems,"primary-key":"id","thead-tr-class":"tabe__row tabe__row--header","tbody-tr-class":_vm.rowClass},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_c('span',{staticClass:"tabe__header-value"},[_vm._v(_vm._s(label))])]}},{key:"cell(interact)",fn:function(ref){
var item = ref.item;
return [(item.type !== 'series')?_c('div',{staticClass:"tabe__cell-interact"},[_c('label',{staticClass:"tabe__check",class:{ bookmarkable: _vm.bookmarkable }},[_c('input',{attrs:{"type":"checkbox","disabled":item.status},domProps:{"checked":_vm.isSelected(item)},on:{"change":function($event){return _vm.toggleSelected(item)}}})]),(_vm.bookmarkable)?_c('BookmarkToggle',{attrs:{"name":"match","value":_vm.isBookmarked(item.id)},on:{"update":function($event){return _vm.toggleBookmark(item, $event)}}}):_vm._e()],1):_vm._e()]}},{key:"cell(selected)",fn:function(ref){
var item = ref.item;
return [_c('label',[_c('input',{attrs:{"type":"checkbox","disabled":item.status},domProps:{"checked":_vm.isSelected(item)},on:{"change":function($event){return _vm.toggleSelected(item)}}})])]}},{key:"cell(bookmark)",fn:function(data){return [_c('BookmarkToggle',{attrs:{"value":_vm.isBookmarked(data.item.id)},on:{"update":function($event){return _vm.toggleBookmark(data.item.id, $event)}}})]}},{key:"cell(created)",fn:function(ref){
var item = ref.item;
return [_c('time',{staticClass:"tabe__datetime",attrs:{"datetime":_vm._f("isoString")(item.created)}},[_c('span',{staticClass:"tabe__cell-value tabe__cell-date"},[_vm._v(_vm._s(_vm._f("localDate")(item.created)))]),_c('span',{staticClass:"tabe__cell-value tabe__cell-time"},[_vm._v(_vm._s(_vm._f("localTime")(item.created)))])])]}},{key:"cell(map)",fn:function(ref){
var ref_item = ref.item;
var map = ref_item.map;
var format = ref_item.format;
return [(map)?_c('MapCell',{staticClass:"tabe__map",attrs:{"id":map,"variant":"col","size":"lg"}}):_c('span',[_vm._v(_vm._s(format ? format : 'N/A'))])]}},{key:"cell(winnerTeam)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tabe__element"},[_c('TeamCell',_vm._b({staticClass:"tabe__element-info",attrs:{"link":false,"to":{ query: { s: _vm.$route.query.s, map_ids: [item.map] } },"tracking-context":_vm.getTrackingContext(item),"variant":"row-reverse","is-collegiate":_vm.isCollegiate}},'TeamCell',_vm.getWinnerTeam(item),false)),(_vm.getWinnerTeam(item).agent_ids)?_c('AgentComposition',{attrs:{"agents":_vm.getWinnerTeam(item).composition,"highlight":_vm.calcHighlightAgents(_vm.getWinnerTeam(item)),"variant":_vm.getWinnerTeam(item).grid}}):_vm._e()],1)]}},{key:"cell(score)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tabe__cell-score-wrapper"},[_c('span',{class:item.type === 'series' || !item.finished ? 'tabe__cell-score-loser' : 'tabe__cell-score-winner'},[_vm._v(_vm._s(!item.finished || _vm.getWinnerTeam(item).rounds_won == null ? '-' : _vm.getWinnerTeam(item).rounds_won))]),_c('span',{staticClass:"tabe__cell-score-separator"},[_vm._v(" :")]),_c('span',{staticClass:"tabe__cell-score-loser"},[_vm._v(_vm._s(!item.finished || _vm.getLoserTeam(item).rounds_won == null ? '-' : _vm.getLoserTeam(item).rounds_won))])]),(item.type !== 'series')?_c('div',{staticClass:"tabe__cell-breakdown-wrapper"},[_c('Breakdown',{attrs:{"breakdown":_vm.calcBreakdown(_vm.getWinnerTeam(item))}}),_c('span'),_c('Breakdown',{attrs:{"breakdown":_vm.calcBreakdown(_vm.getLoserTeam(item))}})],1):_vm._e()]}},{key:"cell(winnerTeamScore)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getWinnerTeam(item).num_points)+" ")]}},{key:"cell(loserTeamScore)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getLoserTeam(item).num_points)+" ")]}},{key:"cell(loserTeam)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tabe__element"},[_c('TeamCell',_vm._b({staticClass:"tabe__element-info",attrs:{"link":false,"to":{ query: { s: _vm.$route.query.s, map_ids: [item.map] } },"tracking-context":_vm.getTrackingContext(item),"variant":"row","is-collegiate":_vm.isCollegiate}},'TeamCell',_vm.getLoserTeam(item),false)),(_vm.getLoserTeam(item).agent_ids)?_c('AgentComposition',{attrs:{"agents":_vm.getLoserTeam(item).composition,"highlight":_vm.calcHighlightAgents(_vm.getLoserTeam(item)),"variant":_vm.getLoserTeam(item).grid}}):_vm._e()],1)]}},{key:"cell(event)",fn:function(ref){
var item = ref.item;
return [_c('EventCell',_vm._b({attrs:{"variant":"row"}},'EventCell',item.event,false))]}},{key:"cell(rounds_played)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"tabe__cell-value"},[_vm._v(_vm._s(item.rounds_played || '-'))])]}},{key:"cell(vod_status)",fn:function(ref){
var item = ref.item;
return [_c('VodStatusIndicator',{attrs:{"status":item.vod_status,"match_id":item.id}})]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"px-1"},[_c('GridVodStatusIndicator',{attrs:{"emea-match-vod-status":item.emeaMatchVodStatus,"series-state":item.seriesState,"scrim":_vm.scrims,"match-id":item.id,"map-id":item.map,"api-key":_vm.apiKey}}),(!item.gridSeriesValid)?_c('div',{staticClass:"text-danger",attrs:{"title":"Might have wrong or incomplete data"}},[_vm._v("⚠")]):_vm._e()],1)]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [(item.type === 'game')?_c('GridMatchButtonsController',_vm._g({attrs:{"api-key":_vm.apiKey,"item":item,"scrims":_vm.scrims,"tracking-context":_vm.trackingContext}},_vm.$listeners)):_vm._e()]}},{key:"cell(quality)",fn:function(ref){
var item = ref.item;
return [_c('CalcMatchQualityCell',{attrs:{"item":item}})]}},_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)},'AppTable',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }