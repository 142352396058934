<template>
  <ApiLazyLoadingController :fetch="fetch" :params="fetchParams" :next-params="nextParams" :has-next="hasNext">
    <template #default="{ data }">
      <GridMatchTable
        v-if="data && data.length > 0"
        :items="data"
        :sort-by="'created'"
        :sort-desc="true"
        :api-key="apiKey"
        v-bind="$attrs"
        v-on="$listeners"
        :bookmarkable="false"
        :highlight-agents="filter.agent_ids"
        :highlight-agents-option="filter.agent_option"
        :scrims="feed === 'SCRIM'"
      />
      <div v-else class="text-muted text-center">
        There are no results matching your filters.
        <router-link :to="{ query: { s: noFiltersSearch } }" class="router-link-active">Try without.</router-link>
      </div>
    </template>
  </ApiLazyLoadingController>
</template>

<script>
import px from 'vue-types'
import { mapGetters } from 'vuex'

import { getEsportMatchIds, getMatchDetails } from '@/api/grid.js'
import ApiLazyLoadingController from '@/components/controllers/ApiLazyLoadingController.vue'
import { pxNullable } from '@/components/Map/types.js'
import { DEFAULT_ITEMS_PER_PAGE, ALL_RESULTS_FILTERS } from '@/store/modules/search.js'
import { periodToApi } from '@/utils/period'
import { serializeQuery } from '@/utils/searchQuery.js'

import GridMatchTable from './GridMatchTable.vue'

export default {
  name: 'GridMatchSearchController',
  components: {
    ApiLazyLoadingController,
    GridMatchTable,
  },
  props: {
    filter: px.object,
    perPage: pxNullable(px.integer).def(null),
    table: pxNullable(px.object).def(null),
    apiKey: px.string,
    feed: px.oneOf(['LOOPFEED', 'SCRIM', 'ESPORTS']).def('ESPORTS'),
    past: Boolean,
    current: Boolean,
    future: Boolean,
    includeInvalid: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    localPerPage: DEFAULT_ITEMS_PER_PAGE,
    sortBy: 'created_millis',
    sortDesc: true,
    noFiltersSearch: null,
    hasNext: false,
    nextParams: null,
  }),
  computed: {
    ...mapGetters({
      agentsByNameLowercase: 'static/agentsByNameLowercase',
      can_access_grid_emea_matches: 'auth/can_access_grid_emea_matches',
      can_access_public_matches: 'auth/can_access_public_matches',
      mapsByAnything: 'static/mapsByAnything',
    }),
    fetchNoFiltersSearch() {
      return this.noFiltersSearch === null
    },
    apiSortBy() {
      switch (this.sortBy) {
        case 'created_millis':
          return 'created'
        case 'summary.round_count':
          return 'rounds_played'
        case 'diff':
          return 'rounds_won_diff'
        default:
          return this.sortBy
      }
    },
    fetchParams() {
      return Object.freeze({
        ...this.filter,
        sortBy: this.apiSortBy,
        sortDesc: this.sortDesc,
      })
    },
    perPageValue: {
      get() {
        return this.perPage != null ? this.perPage : this.localPerPage
      },
      set(value) {
        this.localPerPage = value
        this.$emit('update:per-page', value)
      },
    },
    tableComponent() {
      return this.table || GridMatchTable
    },
  },
  watch: {
    fetchNoFiltersSearch: {
      async handler(val) {
        if (val) {
          this.noFiltersSearch = await serializeQuery(ALL_RESULTS_FILTERS)
        }
      },
      immediate: true,
    },
  },
  methods: {
    async fetch(params, nextParams, config) {
      return await this.getMatches({ ...params, ...nextParams }, { ...config, apiKey: this.apiKey })
    },
    onSortChanged(event) {
      this.sortBy = event.sortBy
      this.sortDesc = event.sortDesc
    },
    async getMatches(params, config) {
      const seriesList = await getEsportMatchIds(
        {
          period: periodToApi(
            this.past
              ? { begin_time: params.begin_time, end_time: params.end_time }
              : { begin_time: Date.now() - 10 * 60 * 60 * 1000, end_time: Date.now() + 24 * 60 * 60 * 1000 }
          ),
          cursor: params.cursor || null,
          teamIds: params.teams && params.teams.length > 0 ? params.teams.map(team => team.id) : undefined,
          tournamentIds:
            this.feed === 'ESPORTS' && params.events && params.events.length > 0
              ? params.events.map(event => event.id)
              : undefined,
          seriesType: this.feed,
        },
        config
      )
      this.hasNext = seriesList.data?.allSeries?.pageInfo?.hasNextPage || false
      this.nextParams = { cursor: seriesList.data?.allSeries?.pageInfo?.endCursor } || null

      const res = Object.freeze(
        await Promise.all(
          seriesList.data?.allSeries?.edges.map(async edge => {
            const startTimeScheduled = edge.node?.startTimeScheduled
            const seriesId = edge.node?.id
            const seriesState = (await getMatchDetails({ seriesId }, config))?.data?.seriesState

            const event = {
              id: edge.node?.tournament?.id,
              name: edge.node?.tournament?.name,
              logo_url: edge.node?.tournament?.logoUrl,
            }

            if (!seriesState) {
              if (!this.current && !this.future) {
                return null
              }
            } else {
              if (!seriesState.valid && !this.includeInvalid) {
                return null
              }

              if (seriesState.finished) {
                if (!this.past) {
                  return null
                }
              } else if (seriesState.started) {
                if (!this.current) {
                  return null
                }
              } else {
                if (!this.future) {
                  return null
                }
              }
            }

            if (
              (!seriesState && new Date(startTimeScheduled).getTime() > Date.now()) ||
              (seriesState && !seriesState.started)
            ) {
              return this.future
                ? Object.freeze({
                    id: seriesId,
                    seriesId: seriesId,
                    created: startTimeScheduled,
                    future: true,
                    event: event,
                    type: 'series',
                    map: null,
                    format: edge.node?.format?.nameShortened || edge.node?.format?.name,
                    teams: edge.node?.teams.map(team => {
                      return {
                        name: team.baseInfo?.name,
                        team_side: 'n/a side',
                        is_self_team: edge.node?.teams[0].baseInfo?.id === team.baseInfo?.id,
                        id: team.baseInfo?.id,
                        logo_url: team.baseInfo?.logoUrl,
                      }
                    }),
                  })
                : null
            }

            const games = seriesState
              ? seriesState.games
                  ?.map(game => ({
                    gameDetails: game,
                    map: this.mapsByAnything[game.map?.name?.toLowerCase()],
                    source: 'grid' /* Details from Grid graphQL API */,
                  }))
                  ?.filter(({ map }) => map)
                  ?.reverse()
              : []

            return await Promise.all(
              games
                .map(({ gameDetails, map, source }) => ({
                  gameDetails,
                  map,
                  source,
                }))
                .map(async ({ gameDetails, map, source }) => {
                  try {
                    return Object.freeze({
                      id: gameDetails.id,
                      seriesId: seriesId,
                      created: seriesState?.startedAt || gameDetails.started || startTimeScheduled,
                      event: event,
                      type: 'game',
                      started: gameDetails.started || gameDetails.start,
                      finished: gameDetails.finished || gameDetails.end,
                      gridSeriesValid: seriesState?.valid,
                      seriesState,
                      map: map.id,
                      rounds_played: gameDetails.segments ? gameDetails.segments.length : gameDetails.rounds.length,
                      rounds_won_diff: Math.abs(gameDetails?.teams[0].score - gameDetails?.teams[1].score),
                      source,
                      teams: gameDetails?.teams.map(team => {
                        let team_side = 'n/a side'
                        const roundStats = (gameDetails.segments ? gameDetails.segments : gameDetails.rounds).reduce(
                          (acc, segment) => {
                            const segmentTeam = segment.teams.find(t => t.id === team.id)
                            if (segment.sequenceNumber <= 12) {
                              if (segmentTeam.side === 'attacker') {
                                team_side = 'Red'
                              } else if (segmentTeam.side === 'defender') {
                                team_side = 'Blue'
                              }
                            } else if (segment.sequenceNumber <= 24) {
                              if (segmentTeam.side === 'attacker') {
                                team_side = 'Blue'
                              } else if (segmentTeam.side === 'defender') {
                                team_side = 'Red'
                              }
                            }
                            const won = segmentTeam.won
                            return {
                              rounds_won: acc.rounds_won + (won ? 1 : 0),
                              rounds_first_half_won:
                                acc.rounds_first_half_won + (won && segment.sequenceNumber <= 12 ? 1 : 0),
                              rounds_second_half_won:
                                acc.rounds_second_half_won +
                                (won && segment.sequenceNumber > 12 && segment.sequenceNumber <= 24 ? 1 : 0),
                              rounds_overtime_won:
                                acc.rounds_overtime_won + (won && segment.sequenceNumber > 24 ? 1 : 0),
                            }
                          },
                          { rounds_won: 0, rounds_first_half_won: 0, rounds_second_half_won: 0, rounds_overtime_won: 0 }
                        )
                        return {
                          name: team.name,
                          team_side,
                          ...roundStats,
                          is_self_team: gameDetails?.teams[0].id === team.id,
                          id: team.id,
                          win: team.won,
                          logo_url: edge.node?.teams?.find(listTeam => listTeam.baseInfo?.id === team.id)?.baseInfo
                            ?.logoUrl,
                          agent_ids: team.players
                            .filter(player => player?.participationStatus === 'active' && player?.character?.name)
                            .map(player => player.character.name.toLowerCase())
                            .filter((name, index, array) => array.indexOf(name) === index)
                            .map(character => this.agentsByNameLowercase[character]?.id),
                        }
                      }),
                    })
                  } catch (error) {
                    console.log('parse grid match error', error, gameDetails)
                    return null
                  }
                })
            )
          }) || []
        )
      )
        .flat()
        .filter(Boolean)
        .filter(match => {
          // Apply client side filters
          if (params?.map_ids && params?.map_ids.length > 0) {
            if (!params.map_ids.includes(match.map)) {
              return false
            }
          }

          if (params?.agent_ids && params?.agent_ids.length > 0) {
            if (
              !match.teams.some(team =>
                params.agent_option === 'and'
                  ? params.agent_ids.every(agent_id => team.agent_ids.includes(agent_id))
                  : params.agent_ids.some(agent_id => team.agent_ids.includes(agent_id))
              )
            ) {
              return false
            }
          }
          return true
        })

      if (res.length === 0 && this.hasNext) {
        return this.fetch(params, this.nextParams, config)
      }

      return res
    },
  },
}
</script>

<style scoped></style>
