<template>
  <div class="tabe__cell-actions-wrapper">
    <router-link
      v-if="false"
      class="tabe__btn-action tabe__btn-action--stats"
      :to="statsPath"
      @click.native="trackStatsClick($event)"
    >
      <span class="tabe__btn-value">Stats</span>
    </router-link>

    <router-link
      class="tabe__btn-action tabe__btn-action--map"
      v-if="!scrims && can_access_grid_emea_matches"
      :is="live ? 'router-link' : 'span'"
      :class="{ disabled: !live }"
      :to="getMapToolPath(item, false, true)"
      v-on="{
        ...(live
          ? {
              'click.native': e => {
                trackMapClick(e, item)
              },
            }
          : {}),
      }"
    >
      <span class="tabe__btn-value">
        Live Map
        <MiniSpinner v-if="loading" class="mr-2" />
      </span>
    </router-link>

    <router-link
      class="tabe__btn-action tabe__btn-action--map"
      :is="ready ? 'router-link' : 'span'"
      :class="{ disabled: !ready }"
      :to="getMapToolPath(item, false)"
      v-on="{
        ...(ready
          ? {
              'click.native': e => {
                trackMapClick(e, item)
              },
            }
          : {}),
      }"
    >
      <span class="tabe__btn-value">
        2D Map
        <MiniSpinner v-if="loading" class="mr-2" />
      </span>
    </router-link>
    <!-- Isn't connected to anything so unsure why this is here -->
    <!--     <button v-if="scrims" type="button" class="tabe__btn-action tabe__btn-action--export" @click="exportMatch">
      <span class="tabe__btn-value">Export</span>
    </button> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import MiniSpinner from '@/components/generic/MiniSpinner.vue'
import mixpanel from '@/mixpanel'

export default {
  name: 'GridMatchButtons',
  components: {
    MiniSpinner,
  },
  props: {
    item: Object,
    loading: Boolean,
    scrims: Boolean,
    trackingContext: Object,
  },
  computed: {
    ...mapGetters({
      can_access_grid_emea_matches: 'auth/can_access_grid_emea_matches',
      getMapById: 'static/getMapById',
    }),
    live() {
      return this.item?.live || this.loading
    },
    ready() {
      return this.item?.ready || this.loading
    },
    statsPath() {
      if (!this.item?.seriesId) return
      return {
        path: `/grid/match/${this.item.seriesId}/${this.item.id}`,
      }
    },
    trackingContextWithItem() {
      return {
        ...this.trackingContext,
        matchId: this.item.id,
      }
    },
  },
  methods: {
    exportMatch() {
      this.$emit('export', this.item)
    },
    getMapToolPath(item, presenter, live = false) {
      if (!item?.seriesId) return
      const map = this.getMapById(item.map)
      if (!map) return
      return {
        path: live ? `/grid/live2/${item.seriesId}/${map.name}` : `/grid/map/${item.seriesId}/${map.name}`,
        query: {
          presenter: presenter || undefined,
        },
      }
    },
    trackStatsClick($event, ctx = this.trackingContextWithItem) {
      mixpanel.track_link({ type: 'match', href: $event.target.href }, ctx)
    },
    trackMapClick($event, ctx = this.trackingContextWithItem) {
      mixpanel.track_link({ type: 'maptool', href: $event.target.href }, ctx)
    },
    trackVodClick($event, ctx = this.trackingContextWithItem) {
      mixpanel.track_link({ type: 'vod', href: $event.target.href }, ctx)
    },
  },
}
</script>

<style scoped></style>
