import { exportGridMatch } from '@/api/export'
import mixpanel from '@/mixpanel'
import download from '@/utils/download'

/**
 * @param {object} params
 * @param {AbortSignal} signal
 * @param {object} trackOpts
 * @returns {Promise<void>}
 */
export default async function downloadGridMatchesExport(params, { signal, trackOpts } = {}) {
  console.log('exporting grid match', params.seriesId)
  const content = await exportGridMatch(params, { signal })
  if (signal.aborted) {
    throw signal.reason || new DOMException('Aborted', 'AbortError')
  }
  download(content, 'matches.xlsx')
  mixpanel.track_export('grid-match', params.seriesId, trackOpts)
}
